






















import { defineComponent, reactive, ref } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

export default defineComponent({
  props: {
    // vehicle ID
    id: {
      type: String,
      required: true
    }
  },

  setup(props, { root, emit }) {
    const form = ref<any>(null)

    const state = reactive({
      isFormValid: true,
      active: false,
      loading: false
    })

    const model = reactive({
      comment: ''
    })

    const rules = {
      comment: [(v: string) => !!v || 'Podaj treść komentarza']
    }

    const addComment = () => {
      const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

      const data = { text: model.comment }
      state.loading = true

      axiosInstance
        .post(`vehicle/${props.id}/comment`, data)
        .then(() => {
          state.active = false
          emit('add')
        })
        .catch(error => console.log(error))
        .finally(() => state.loading = false)
    }

    const onSubmit = async () => {
      await form.value.validate()
      if (state.isFormValid) addComment()
    }

    return { form, state, model, rules, onSubmit }
  }
})
